import React, { useEffect, useState } from 'react';
import './App.css';
import Oscillator from './Oscillator';
import Socials from './Socials';
import ConfettiExplosion from 'react-confetti-explosion';

/*
todo 
- add logo
- add animations : 
  - on wave
- add socials ✓
  fix twitter share
*/

enum GameState {
  level = 'LEVEL',
  start = 'START',
  play = 'PLAY',
  finish = 'FINISH',
}

export enum Levels {
  easy = 'EASY',
  medium = 'MEDIUM',
  hard = 'HARD',
}
const getRandomFrequency = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min; 
}

function App() {
  const minFrequency = 200;
  const maxFrequency = 1000;
  const scores = new Map();
  scores.set(0,"Perfect !!!");
  scores.set(5,"Almost Perfect!!");
  scores.set(15,"Very Good!");
  scores.set(50,"Good");
  scores.set(100,"Not so bad");
  scores.set(200,"Hmm");
  scores.set(500,"let's try again ;)");

  const [gameState, setGameState] = useState<GameState>(GameState.level);
  const [frequencyToFind, setFrequencyToFind] = useState<number>(getRandomFrequency(minFrequency, maxFrequency));
  const [frequencyToStart, setFrequencyToStart] = useState<number>(getRandomFrequency(minFrequency, maxFrequency));
  const [result, setResult] = useState<number>(1000);
  const [level, setLevel] = useState<Levels>(Levels.easy);
  const [durationFirst, setDurationFirst] = useState(1000);
  const [durationSecond, setDurationSecond] = useState(4000);
  const [score, setScore] = useState<string>("");
  const [isExploding, setIsExploding] = useState(false);

  const onFirstFrequencyFinish = (freq : number) => {
    setGameState(GameState.play);
  }

  const onSecondFrequencyFinish = (freq : number) => {
    const gameResult = Math.abs(frequencyToFind - freq); 
    setResult(gameResult);
    setScore(displayScore(gameResult));
    setGameState(GameState.finish);
    if(gameResult < 100){
      setIsExploding(true);
    }
    // todo change the explosion according to the score
  }

  const replay = () => {
    setFrequencyToFind(getRandomFrequency(minFrequency, maxFrequency));
    setFrequencyToStart(getRandomFrequency(minFrequency, maxFrequency));
    setGameState(GameState.level);
    setIsExploding(false);
  }

  const setlevelAndStart = (l : Levels) => {
    setLevel(l);
    switch (l) {
      case Levels.easy:
        setDurationFirst(3000);
        setDurationSecond(8000);
        break;
      case Levels.medium:
        setDurationFirst(2000);
        setDurationSecond(5000);
        break;
      case Levels.hard:
        setDurationFirst(1000);
        setDurationSecond(3000);
        break;
    }
    setGameState(GameState.start);
  }

  const displayRules = () => {
    const rules = `
    EarWeGo is a small game which goal is to help you discover/enhance your hearing accuracy :)
    1- First you will have to listen to a random frequency: pay attention, you have to memorize it!
    2- Then another frequency will be played, and by turning the little knob you'll have to try to get as close as possible to the previous one you've heard.
    `;
    window.alert(rules);
  }

  const displayScore = (gameResult : number): string => {
    let text = "";
    scores.forEach((value, key, map) =>{
      if(gameResult <= key){
        if(text===""){
          text = value;
        }
      }
    })
    return text === "" ? scores.get(500) : text;
  }

  return (
    <div className='center margesimpson'>
      {isExploding && <ConfettiExplosion />}
      {(() => {
        switch(gameState) {
          case GameState.level:
            return <div className='subContainer'>
              <div>🔊 Sound on!! 🔊</div>
              <div>choose your level:</div>
              <div className="btn easy" onClick={() => {setlevelAndStart(Levels.easy)}}>Easy</div>
              <div className="btn medium" onClick={() => {setlevelAndStart(Levels.medium)}}>Medium</div>
              <div className="btn hard" onClick={() => {setlevelAndStart(Levels.hard)}}>Hard</div>
              <div className="btn rules" onClick={displayRules}>Rules</div>
            </div>
          case GameState.start:
            return <><div>Play the first frequency</div>
              <Oscillator key="start" startFrequency={frequencyToFind} duration={durationFirst} onFinish={onFirstFrequencyFinish}/>
            </>
            
          case GameState.play:
            return <><div>Play the first frequency</div>
            <Oscillator key="play" startFrequency={frequencyToStart} duration={durationSecond} range={[0, 1000]} onFinish={onSecondFrequencyFinish}/>
            </>
          case GameState.finish:
            return <div className='center'>
                    <div>Your score: {800-result} / 800 {score}</div>
                    <Socials currentLevel={level}/>
                    <div className="btn play" onClick={replay}>Replay</div>
                    </div>
          default:
            return null
        }
      })()}
    </div>
  );
}

export default App;
