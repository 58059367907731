import React, { useEffect, useRef, useState } from "react";

type OscillatorProps = {
  startFrequency: number;
  duration: number;
  onFinish: Function;
  style?: any;
  range?: Array<number>;
};

const Oscillator = ({
  startFrequency,
  duration,
  range,
  onFinish,
  style,
}: OscillatorProps) => {
  const audioCtx = useRef<AudioContext>();
  const oscillator = useRef<OscillatorNode | null>(null);
  const [frequency, setFrequency] = useState<number>(startFrequency);
  const [isPlaying, setIsplaying] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(duration);

  /*
  Browsers only allow to have 2 AudioContext at the same time
  So we need to close it everytime the component unmount to avoid duplication
   */
  useEffect(() => {
    audioCtx.current = new AudioContext();
    return () => {
      audioCtx.current?.close();
    };
  }, []);

  const handleFrequencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFrequency = parseFloat(event.target.value);
    setFrequency(newFrequency);
    if (oscillator.current && audioCtx.current) {
      oscillator.current.frequency.setValueAtTime(
        newFrequency,
        audioCtx.current.currentTime
      );
    }
  };

  const handleStart = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isPlaying || !audioCtx.current) return;
    setIsplaying(true);
    oscillator.current = audioCtx.current.createOscillator();
    oscillator.current.type = "sine";
    oscillator.current.frequency.setValueAtTime(
      frequency,
      audioCtx.current.currentTime
    );
    oscillator.current.connect(audioCtx.current.destination);
    oscillator.current.start();

    const step = 100;
    const interval = setInterval(() => {
      setRemainingTime((remainingTime) => remainingTime - step);
    }, step);

    setTimeout(() => {
      clearInterval(interval);
      handleStop();
    }, duration);
  };

  const handleStop = () => {
    if (oscillator.current) {
      setRemainingTime((remaintingTime) => remaintingTime + duration);
      const currentFreq = oscillator.current.frequency.value;
      oscillator.current.stop();
      oscillator.current.disconnect();
      oscillator.current = null;
      setIsplaying(false);
      onFinish(currentFreq);
    }
  };
  let RangeComponent;
  if (range) {
    RangeComponent = (
      <input
        type="range"
        className="range"
        min={range[0]}
        max={range[1]}
        step="1"
        value={frequency}
        id="frequency"
        name="frequency"
        onChange={handleFrequencyChange}
      />
    );
  } else {
    <div />;
  }
  <br />;
  return (
    <div className="center subContainer rangeContainer">
      {RangeComponent}
      <div className="btn play" onClick={handleStart}>Play</div>
      <div>Remaining time: {(remainingTime / 1000).toFixed(1)}</div>
    </div>
  );
};

export default Oscillator;
