import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import { Levels } from './App';

type SocialsProps = {
    currentLevel: Levels
}
const Socials = ({currentLevel} : SocialsProps) => {
    return (
    <div className='center'>
    <div>Share you score on your socials!</div>
    <div>
    <TwitterShareButton
    url={"https://earwego.fr/"}
    title={`Ear we go !! I just scored 1000 in ${currentLevel} mode`}
    className=""
    via="earwegothegame"
    hashtags={["EarWeGo"]}
    >
    <TwitterIcon
        size={32}
        round />
    </TwitterShareButton>
    <FacebookShareButton
    url={"https://earwego.fr/"}
    quote={"Ear we go !! I just scored 1000 in Hard mode"}
    hashtag="EarWeGo"
    >
    <FacebookIcon
        size={32}
        round />
    </FacebookShareButton>
    </div>
    </div>
    )
}

export default Socials;